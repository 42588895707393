var App = App || {};
App.Config = App.Config || {};

// Merges the global config
if (typeof AppConfig === 'object') {
    App.Config = AppConfig;
}

/**
 * Getter
 *
 * @param object
 * @param path
 * @returns {*}
 */
App.Config.get = function (path, default_value) {
    var getter;

    if (typeof path == 'array') {
        path = path.join(".");
    }

    if (typeof this._getters == 'undefined') {
        this._getters = {};
    }

    if (!(getter = this._getters[path])) {
        getter = this._getters[path] = new Function("return this." +path);
    }

    try {
        return getter.call(this);
    } catch (e) {
        return typeof default_value != 'undefined' ? default_value : void 0;
    }
};
