;(function () {
  'use strict'

  jQuery(function ($) {

    if (typeof window['ga'] !== 'undefined') window.ga('set', 'transport', 'beacon');

    function clickHandler(e) {
      var $target = $(this).is('form') ? $(this).find('[type=submit]') : $(this);
      var elem = $target.data('gaCategorie');
      var action = $target.data('gaAction');
      var code = $target.data('gaLibelle');
      var label = $target.data('gaConvLabel');
      var that = this;

      var finalize = function () {
        if ($(that).is('form')) {
          that.submit();
        } else if ($(that).is('a')) {
          window.location = that.href;
        }
      };

      Promise.all([
        new Promise(function (resolve) {
          if (typeof window['ga'] == 'undefined') resolve();
          if (!elem || !action || !code) resolve();
          if (navigator.sendBeacon) {
            window.ga('send', 'event', elem, action, code);
            resolve();
          } else {
            if ($(that).is('a')) e.preventDefault();
            window.ga('send', 'event', elem, action, code, {
              hitCallback: createFunctionWithTimeout(resolve)
            });
          }
        })
      ]).then(finalize).catch(finalize);
    }

    function createFunctionWithTimeout(callback, opt_timeout) {
      var called = false;

      function fn() {
        if (!called) {
          called = true;
          callback();
        }
      }

      setTimeout(fn, opt_timeout || 1000); // called after timeout
      return fn; // or called sooner by analytics
    }

    $('.tracker-ga').click(clickHandler);
    $(".tracker-ga-parent").on("click", ".tracker-ga-child", clickHandler);
    $("body").on("submit", "form", clickHandler);
  });
}.apply(this))
