"use strict";

var replaceUrlParam = function (url, paramName, paramValue) {
    if (!url) {
        return null;
    }
    if (paramValue == null) {
        paramValue = '';
    }
    var pattern = new RegExp('\\b(' + paramName + '=).*?(&|$)')
    if (url.search(pattern) >= 0) {
        return url.replace(pattern, '$1' + paramValue + '$2');
    }
    return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue
}

var redirectTo = function (el, ui, prefix) {
    var url = el.attr('action');
    if (ui) {
        var value = ui.item.value;
    } else {
        value = el.find(':selected').val();
    }

    url = url.replace('.html', prefix + value + '.html');
    window.location.href = url;
}

// Expands more programmes
$('.showMore').on('click', function (e) {
    e.preventDefault();
    var $target = $(this).data('target');

    $(this).toggleClass('active');
    $('#' + $target).toggleClass('active');
});

$("#stands").length && $("#stands").val('');
$("#domaines").length && $("#domaines").val('');

// Stand form filter
$("#standsFilter").on("change", function (event) {
    (typeof window['ga'] !== 'undefined') && ga('send', 'event', 'programme', 'filtre', 'stands');
    redirectTo($(this), false, '/');
});

// Domaines form filter
$("#domainesFilter").on("autocompleteselect", function (event, ui) {
    (typeof window['ga'] !== 'undefined') && ga('send', 'event', 'programme', 'filtre', 'domaine');
    redirectTo($(this), ui, '/domaine/');
});

// Pub open in new window
$('.open-in-new-indow').on('click', function(e) {
    e.preventDefault();
    window.open($(e.currentTarget).attr('href'), "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,menubar=yes");
});

// Submit AJAX like filter
$('.search-filter').on('click', function(e) {
    var url = $('#main-search-form').attr('action');
    var value = $(e.currentTarget).val();
    url = replaceUrlParam(url, 'keywords', $("#keywords").val());
    (typeof window['ga'] !== 'undefined') && ga('send', 'event', 'resultats-recherche', 'filtre', value || 'tous');
    window.location.href = replaceUrlParam(url, 'filter', value);
});

// submit nav search
$('#nav_search_form_submit').on('click', function(e) {
    e.preventDefault();
    var target = $(e.target);
   if (target.hasClass('clicked') && $('.nav_search_form_input').val() != '') {
       target.removeClass('clicked');
       $('#nav_search_form').submit();
   } else {
       target.addClass('clicked');
       $('.nav_search_form_input').toggleClass('active');
   }
});

var newsletterForm = $('.newsletter-form');
if(newsletterForm.length) {
    newsletterForm.find('button').on('click', function (e) {
        e.preventDefault();

        console.log(newsletterForm);
        var recaptchaResponse = newsletterForm.find('.g-recaptcha-response').val();
        console.log(recaptchaResponse == '');
        if(!recaptchaResponse || recaptchaResponse == '') {
            $('.js-response-form').html('Veuiller valider le recaptcha');
        } else {
            newsletterForm.submit();
        }
    });
}
