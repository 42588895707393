var Intl = Intl || {};

(function() {

    Intl.messages = {};

    // Populates the messages using app configuration
    if (typeof App !== 'undefined' && App.Config) {
        Intl.messages = App.Config.get('i18n.messages', {});
    }

    /**
     * Getter
     *
     * @param object
     * @param path
     * @returns {*}
     */
    Intl.get = function(name, default_value) {
        if (Intl.messages && Intl.messages[name]) {
            return Intl.messages[name];
        }
        return default_value;
    };

    /**
     * Getter for plural
     *
     * @param object
     * @param path
     * @returns {*}
     */
    Intl.nget = function(num, single, plural, zero) {
        if (num == 0) {
            return this.get(zero || single);
        } else if (num > 1) {
            return this.get(plural);
        } else {
            return this.get(single);
        }
    };
})();

/**
 * Global shortcuts
 */
window.t = function() {
    return Intl.get.apply(Intl, arguments);
};
window.tn = function() {
    return Intl.nget.apply(Intl, arguments);
};
